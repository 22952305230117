<template>
  <div>
    <!-- User Info: Input Fields -->
    <div class="d-flex">
      <feather-icon
          icon="ClipboardIcon"
          size="19"
      />
      <h4 class="mb-0 ml-50">
        {{$t('Customer')}}
      </h4>
    </div>
    <b-form class="mt-1">
      <b-row>
        <!-- Field: CustomerNumber -->
        <b-col
            cols="12"
            md="6"
            lg="4"
        >
          <b-form-group
            :label="$t('CustomerNumber')"
            label-for="CustomerNumber"
          >
            <b-form-input
              id="customer-number"
              v-model="customerData.CustomerNumber"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Name -->
        <b-col
            cols="12"
            md="6"
            lg="4"
        >
          <b-form-group
            :label="$t('CustomerName')"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="customerData.Name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Short Name -->
        <b-col
            cols="12"
            md="6"
            lg="4"
        >
          <b-form-group
            :label="$t('CustomerShortName')"
            label-for="short-name"
          >
            <b-form-input
              id="short-name"
              v-model="customerData.ShortName"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Vkn/Tc -->
        <b-col
            cols="12"
            md="6"
            lg="4"
        >
          <b-form-group
            :label="$t('CustomerVknTc')"
            label-for="CustomerVkn-Tc"
          >
            <b-form-input
              id="customer-vkn-tc"
              v-model="customerData.Vkn"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Tax Office -->
        <b-col
            cols="12"
            md="6"
            lg="4"
        >
          <b-form-group
            :label="$t('CustomerTaxOffice')"
            label-for="CustomerTaxOffice"
          >
            <b-form-input
              id="customer-TaxOffice"
              v-model="customerData.TaxOffice"
            />
          </b-form-group>
        </b-col>
        </b-row>
      </b-form>
        <div class="d-flex mt-2">
          <feather-icon
              icon="PhoneIcon"
              size="19"
          />
          <h4 class="mb-0 ml-50">
            {{$t('Contact')}}
          </h4>
        </div>
    <b-form class="mt-2">
      <b-row>
        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('CustomerEmail')"
            label-for="CustomerEmail"
          >
            <b-form-input
              id="customer-email"
              v-model="customerData.Email"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Phone -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('CustomerPhone')"
            label-for="CustomerPhone"
          >
            <b-form-input
              id="customer-phone"
              v-model="customerData.Phone"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Fax -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('CustomerFax')"
            label-for="CustomerFax"
          >
            <b-form-input
              id="customer-fax"
              v-model="customerData.Fax"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <div class="d-flex mt-2">
      <feather-icon
          icon="InfoIcon"
          size="19"
      />
      <h4 class="mb-0 ml-50">
        {{$t('Info')}}
      </h4>
    </div>
    <b-form class="mt-2">
      <b-row>
        <!-- Field: SecretInfo -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('CustomerSecretInfo')"
            label-for="CustomerSecretInfo"
          >
            <b-form-input
              id="customer-SecretInfo"
              v-model="customerData.SecretInfo"
            />
          </b-form-group>
        </b-col>
        <!-- Field: MaturityOption -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('CustomerMaturityOption')"
            label-for="CustomerMaturityOption"
          >
            <b-form-input
              id="customer-MaturityOption"
              v-model="customerData.MaturityOption"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

<!--     Action Buttons
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="CustomerAdd()"
    >
      Save Changes
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>-->
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import config from '@/config'
import useUsersList from '../list/useUsersList'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    customerData: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.customerData.avatar = base64
    })

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
