<template>
  <div>
    <component :is="AllData.customer === undefined ? 'div' : 'b-card'">

      <!-- Alert: No item found -->
      <b-alert
          variant="danger"
          :show="AllData.customer === undefined"
      >
        <h4 class="alert-heading">
          Error fetching user data
        </h4>
        <div class="alert-body">
          No user found with this user id. Check
          <b-link
              class="alert-link"
              :to="{ name: 'apps-users-list'}"
          >
            User List
          </b-link>
          for other users.
        </div>
      </b-alert>

      <b-tabs
          v-if="AllData.customer"
          pills
      >

        <!-- Tab: Customer -->
        <b-tab active>
          <template #title>
            <feather-icon
                icon="ClipboardIcon"
                size="16"
                class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Customer Information') }}</span>
          </template>
          <user-edit-tab-account
              :customer-data="AllData.customer"
              class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: Adress -->
        <b-tab>
          <template #title>
            <feather-icon
                icon="MapPinIcon"
                size="16"
                class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Adress Information') }}</span>
          </template>
          <user-edit-tab-adress :address="AllData.customerAddresses" class="mt-2 pt-75"/>
        </b-tab>

        <!-- Tab: Personal Information -->
        <b-tab>
          <template #title>
            <feather-icon
                icon="UserIcon"
                size="16"
                class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Personal Information') }}</span>
          </template>
          <user-edit-tab-personel :personel="AllData.customerOfficials" class="mt-2 pt-75"/>
        </b-tab>

        <!-- Tab: Social -->
        <b-tab>
          <template #title>
            <feather-icon
                icon="CreditCardIcon"
                size="16"
                class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Bank Information') }}</span>
          </template>
          <user-edit-tab-social :bank="AllData.customerBanks" class="mt-2 pt-75"/>
        </b-tab>
      </b-tabs>
      <!-- Action Buttons -->

    </component>
    <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-1"
        @click="CustomerAdd()"
    >
      {{ $t('Save') }}
    </b-button>
    <b-button class="mt-1"
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="CustomerReset()"
    >
      {{ $t('Reset') }}
    </b-button>
  </div>

</template>

<script>
import {
  BButton,
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import {ref, onUnmounted} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import storeModule, { APP_STORE_MODULE_NAME } from '../storeModule'
import TabAccount from './TabAccount'
import TabPersonel from './TabPersonel'
import TabSocial from './TabBank'
import TabAdress from './TabAdress'
import config from "@/config";
import {EventBus} from "@/event-bus";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    storeModule,
    TabAccount,
    TabPersonel,
    TabSocial,
    TabAdress,
  },
  data() {
    return {
      id: this.$route.params.id,
      AllData: {
        customer: {
          Name: '',
          Fax: '',
          Vkn: '',
          Tckn: '',
          Email: '',
          Phone: '',
          ShortName: '',
          TaxOffice: '',
          SecretInfo: '',
          CustomerNumber: '',
          MaturityOption: '',
        },
        customerAddresses: [],
        customerOfficials: [],
        customerBanks: [],

      },


      customerDataTckn: '',
      config: {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          Accept: 'application/json',
        }
      },
    }
  },
  setup() {
    const customerData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-user/fetchUser', {id: router.currentRoute.params.id})
        .then(response => {
          customerData.value = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            customerData.value = undefined
          }
        })
  },
  mounted() {
    EventBus.$on('changeaddress', (l) => {console.log(l)});

  },
  watch:{
    AllData(){
      console.log("oldu mu ki?", this.AllData)
    }
  },
  methods: {
    CustomerAdd(){
      console.log("oldu mu ki?", this.AllData)
      if (this.AllData.customer.Vkn.length === 11) {
        this.AllData.customer.Tckn = this.AllData.customer.Vkn
        this.AllData.customer.Vkn = ''
      } else {
        this.AllData.customer.Tckn = ''
      }

      console.log("oldu mu?", this.AllData)


       this.$http.post(`${config.API_BASE}/api/Customers/createcard`,
           this.AllData)
           .then(response => {
             const {data} = response
             console.log('data', this.AllData)
           })
    },
    CustomerReset() {

      this.customer = {
        Name: '',
        Fax: '',
        Vkn: '',
        Tckn: '',
        Email: '',
        Phone: '',
        ShortName: '',
        TaxOffice: '',
        SecretInfo: '',
        CustomerNumber: '',
        MaturityOption: '',
      }

    },
  },
}
</script>

<style>

</style>
