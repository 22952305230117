<template>
  <div>
    <!-- Adress Icon -->
    <div class="d-flex ">
      <feather-icon
          icon="MapPinIcon"
          size="19"
      />
      <h4 class="mb-0 ml-50">
        {{$t('Addres')}}
      </h4>
    </div>
    <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-1"
        @click="repeateAgain"
    >
      <feather-icon
          icon="PlusIcon"
          class="mr-25"
      />
      <span>{{$t('New Addres')}}</span>
    </b-button>
    <div>
      <b-form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form mt-1"
          @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <b-row
            v-for="(item, index) in address"
            :id="item.id"
            :key="item.id"
            ref="row"
        >

          <!-- Field: Address Title -->
          <b-col
              cols="12"
              md="5"
              lg="4"
          >
            <b-form-group
                :label="$t('Addres Title')"
                label-for="address-line-1"

            >
              <b-form-input
                  id="address-line-1"
                  v-model="item.addressTitle"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Counrty -->
          <b-col
              cols="12"
              md="5"
              lg="4"
          >
            <b-form-group
                :label="$t('Country')"
                label-for="address-line-2"
            >
              <b-form-input
                  id="address-line-2"
                  placeholder="Los Santos"
                  v-model="item.country"
              />
            </b-form-group>
          </b-col>

          <!-- Field: City -->
          <b-col
              cols="12"
              md="5"
              lg="4"
          >
            <b-form-group
                :label="$t('City')"
                label-for="postcode"
            >
              <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="languageOptions"
                  :clearable="false"
                  input-id="language"
                  v-model="item.city"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Adress -->
          <b-col
              cols="12"
              md="8"
              lg="8"
          >
            <b-form-group
                :label="$t('Addres')"
                label-for="city"
            >
              <b-form-textarea
                  id="adress"
                  placeholder="Fixed height textarea"
                  rows="3"
                  no-resize
                  v-model="item.address"
              />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col
              cols="12"
              md="3"
              lg="3">
            <div class="demo-inline-spacing">
              <div>
                <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    @click="deleteAdress(index)"
                >
                  {{$t('Delete Addres')}}
                </b-button>
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <!--    <div>
          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                Save Changes
              </b-button>
              <b-button
                variant="outline-secondary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </div>-->
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton,
  BFormTextarea,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import {ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import {heightTransition} from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {EventBus} from "@/event-bus";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      address: [],
      nextTodoId: 2,
      languageOptions:''
    }
  },
  watch: {
    address() {
      console.log("değişti", this.address)
    }
  },

  mounted() {
    this.initTrHeight()
    EventBus.$emit('changeaddress',this.address)
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    deleteAdress(index) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes, delete it!'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.address.splice(index, 1)
          this.trTrimHeight(this.$refs.row[0].offsetHeight)
          this.$swal({
            icon: 'success',
            title: this.$t('Deleted!'),
            text: this.$t('Your addres has been deleted.'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    repeateAgain() {
      this.address.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.address.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {
    const userDataInfo = ref({
      dob: null,
      mobile: '+6595895857',
      website: 'https://rowboat.com/insititious/Angelo',
      language: 'French',
      gender: 'female',
      contactOptions: ['Email', 'Message'],
      addressLine1: 'A-65, Belvedere Streets',
      addressLine2: '',
      postcode: '',
      city: 'New York',
      state: '',
      country: '',
    })

    const languageOptions = [
      'English',
      'Spanish',
      'French',
      'Russian',
      'German',
      'Arabic',
      'Sanskrit',
    ]

    const genderOptions = [
      {text: 'Male', value: 'male'},
      {text: 'Female', value: 'female'},
    ]

    const contactOptionsOptions = ['Email', 'Message', 'Phone']

    return {
      userDataInfo,
      languageOptions,
      genderOptions,
      contactOptionsOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
